<template>
    <div class="flex justify-center text-center items-center">

        <div class="bg-white rounded lg:mt-10 shadow-lg w-2/6 border-l-8 border-green-600">
             <h1 class="py-24">Your Order Completed :)</h1>
        </div>
        
    </div>
</template>

<script>

export default ({

    data() {
      return {

        }
    },


})
</script>

<style scoped>

</style>
